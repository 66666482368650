import React from "react";
import Layout from "../components/Layout";
import Section from "../components/Section";
import { 
  HeaderH1, 
  HeaderH3,
} from "../helpers/styledComponents";
import PDFViewer from "../components/PDFViewer";
import { Button } from "semantic-ui-react";


const headerContent = (
  <>
    <HeaderH1>2020 Impact Report</HeaderH1>
    <div style={{maxWidth: "900px", margin: "auto"}}>
      <HeaderH3>
      Despite a global pandemic, growing civil unrest, and polarizing politics, the 2020 election saw historic voter turnout and the election cycle of 2020 saw historic civic engagement. The Civic Alliance and its business community rallied to champion voter registration, early voting, vote by mail, and civic participation to employees and customers alike - creating a tipping point in corporate civic engagement.
      </HeaderH3>
    </div>
  </>
);


export default () => {
  const filePath = "/articles/civic_alliance_impact_report.pdf";
  return (
  
    <Layout 
      mode="dark" 
      headerContent={headerContent}
      title="2020 Impact Report| Civic Alliance"
    >
      <Section mode="light" extraMargin>
        
        <PDFViewer filePath={filePath} />
        <div style={{textAlign: "center", marginTop: "3em"}}>
          <Button size="small" as="a" href={filePath} target="_blank" color="black" content="Open in Full Screen" />
        </div>

      </Section>
      
    </Layout>
  );
}